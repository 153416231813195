"use strict";

module.exports = function() {

    var versamento500 = {
        '2' : {
            'activitas' : { 'mia' : 986.99 , 'miaplus' :  991.32  },
            'soliditas' : { 'mia' : 989.38, 'miaplus' :  995.50  },
            'serenitas' : { 'mia' : 991.77, 'miaplus' :  984.01  },
            'securitas' : { 'mia' : 988.78, 'miaplus' :  992.36 },
            'aequitas' : { 'mia' : 979.54  , 'miaplus' :  993.86  },
            'non_ricordo' : { 'mia' : 987.29 , 'miaplus' :  991.41  },
        }, 

        '5' : {
            'activitas' : { 'mia' : 2615.56 , 'miaplus' : 2639.07    },
            'soliditas' : { 'mia' : 2628.07, 'miaplus' :  2652.48  },
            'serenitas' : { 'mia' : 2641.43 , 'miaplus' :  2661.19  },
            'securitas' : { 'mia' : 2624.94 , 'miaplus' : 2644.58   },
            'aequitas' : { 'mia' :  2602.31, 'miaplus' :  2625.72  },
            'non_ricordo' : { 'mia' : 2622.46, 'miaplus' :  2644.61   },
        },       

        '10' : {
            'activitas' : { 'mia' : 5659.31  , 'miaplus' : 5757.73     },
            'soliditas' : { 'mia' : 5713.06 , 'miaplus' :  5809.25  },
            'serenitas' : { 'mia' : 5764.14  , 'miaplus' :  5844.96  },
            'securitas' : { 'mia' : 5697.19  , 'miaplus' :  5776.99  },
            'aequitas' : { 'mia' :  5656.16 , 'miaplus' :  5751.33  },
            'non_ricordo' : { 'mia' : 5697.97, 'miaplus' :  5788.05   },
        }, 

        '15' : {
            'activitas' : { 'mia' : 9174.96 , 'miaplus' :  9405.32   },
            'soliditas' : { 'mia' : 9297.01 , 'miaplus' : 9530.87   },
            'serenitas' : { 'mia' : 9420.91 , 'miaplus' :  9626.28  },
            'securitas' : { 'mia' : 9258.67  , 'miaplus' : 9460.02   },
            'aequitas' : { 'mia' : 9205.30  , 'miaplus' :  9444.35  },
            'non_ricordo' : { 'mia' : 9271.37 , 'miaplus' :  9493.37    },
        }, 
         
        '20' : {
            'activitas' : { 'mia' :  13222.49 , 'miaplus' :  13669.83  },
            'soliditas' : { 'mia' : 13458.91, 'miaplus' :  13931.12  },
            'serenitas' : { 'mia' : 13715.52 , 'miaplus' :  14103.28   },
            'securitas' : { 'mia' : 13399.35 , 'miaplus' : 13776.72   },
            'aequitas' : { 'mia' : 13340.09 , 'miaplus' :  13792.07  },
            'non_ricordo' : { 'mia' : 13427.27 , 'miaplus' :  13854.61  },
        },  

        '25' : {
            'activitas' : { 'mia' : 17901.14 , 'miaplus' : 18670.97    },
            'soliditas' : { 'mia' : 18306.97, 'miaplus' :  19124.42   },
            'serenitas' : { 'mia' :  18723.66, 'miaplus' :  19424.70  },
            'securitas' : { 'mia' : 18179.00 , 'miaplus' :  18856.14  },
            'aequitas' : { 'mia' :  18128.10, 'miaplus' :  18936.16  },
            'non_ricordo' : { 'mia' : 18247.78 , 'miaplus' : 19002.48    },
        },   

        '30' : {
            'activitas' : { 'mia' : 23276.58 , 'miaplus' : 24543.45    },
            'soliditas' : { 'mia' : 23920.58 , 'miaplus' :  25228.94  },
            'serenitas' : { 'mia' :  24627.93, 'miaplus' :  25713.23  },
            'securitas' : { 'mia' : 23757.63 , 'miaplus' : 24797.92    },
            'aequitas' : { 'mia' : 23717.10 , 'miaplus' :  25012.35  },
            'non_ricordo' : { 'mia' : 23859.96, 'miaplus' :  25059.18    },
        }, 

        '35' : {
            'activitas' : { 'mia' : 29480.82 , 'miaplus' :  31392.67   },
            'soliditas' : { 'mia' : 30511.75, 'miaplus' :   32436.17  },
            'serenitas' : { 'mia' :  31520.94, 'miaplus' : 33177.09   },
            'securitas' : { 'mia' : 30204.19 , 'miaplus' :  31779.32  },
            'aequitas' : { 'mia' :  30204.19  , 'miaplus' :  32171.54  },
            'non_ricordo' : { 'mia' : 30384.38, 'miaplus' :  32191.36    },
        },    
         
    };

    var versamento1000 = {

        '2' : {
            'activitas' : { 'mia' :  2029.80 , 'miaplus' :  2038.87   },
            'soliditas' : { 'mia' : 2034.63, 'miaplus' :  2043.71  },
            'serenitas' : { 'mia' : 2039.47 , 'miaplus' :  2047.04   },
            'securitas' : { 'mia' :  2033.42, 'miaplus' :  2040.98  },
            'aequitas' : { 'mia' : 2024.97 , 'miaplus' : 2034.03   },
            'non_ricordo' : { 'mia' : 2032.46 , 'miaplus' :  2040.93    },
        }, 

        '5' : {
            'activitas' : { 'mia' : 5339.84  , 'miaplus' :  5389.34    },
            'soliditas' : { 'mia' : 5366.94, 'miaplus' :  5415.05  },
            'serenitas' : { 'mia' : 5392.55 , 'miaplus' :  5432.78  },
            'securitas' : { 'mia' : 5358.95 , 'miaplus' :  5398.97  },
            'aequitas' : { 'mia' :  5339.84, 'miaplus' :  5389.34  },
            'non_ricordo' : { 'mia' : 5359.62, 'miaplus' :  5405.10   },
        }, 

        '10' : {
            'activitas' : { 'mia' :  11528.29 , 'miaplus' :  11722.53   },
            'soliditas' : { 'mia' : 11631.45, 'miaplus' :   11834.16 },
            'serenitas' : { 'mia' :  11742.15, 'miaplus' :  11907.01   },
            'securitas' : { 'mia' : 11605.57 , 'miaplus' :  11768.36  },
            'aequitas' : { 'mia' : 11579.75 , 'miaplus' :  11774.92  },
            'non_ricordo' : { 'mia' : 11617.44, 'miaplus' :  11801.40  },
        }, 

        '15' : {
            'activitas' : { 'mia' :  18671.05, 'miaplus' :  19140.99   },
            'soliditas' : { 'mia' : 18920.03, 'miaplus' : 19397.11   },
            'serenitas' : { 'mia' :  19172.79, 'miaplus' :  19591.75  },
            'securitas' : { 'mia' : 18841.82 , 'miaplus' : 19252.56   },
            'aequitas' : { 'mia' :  18826.22, 'miaplus' : 19316.65   },
            'non_ricordo' : { 'mia' : 18886.38, 'miaplus' :  19339.81   },
        },   

        '20' : {
            'activitas' : { 'mia' : 26887.99 , 'miaplus' :  27831.18   },
            'soliditas' : { 'mia' : 27400.54 , 'miaplus' : 28333.00    },
            'serenitas' : { 'mia' :  27893.34, 'miaplus' :  28684.07  },
            'securitas' : { 'mia' :  27248.62 , 'miaplus' : 28018.15   },
            'aequitas' : { 'mia' :  27248.62 , 'miaplus' : 28206.57    },
            'non_ricordo' : { 'mia' : 27335.82, 'miaplus' : 28214.59    },
        }, 

        '25' : {
            'activitas' : { 'mia' : 36409.02  , 'miaplus' : 37979.46     },
            'soliditas' : { 'mia' : 37236.91, 'miaplus' : 38849.39    },
            'serenitas' : { 'mia' :  38086.95, 'miaplus' :  39517.12  },
            'securitas' : { 'mia' : 36975.84 , 'miaplus' : 38357.22    },
            'aequitas' : { 'mia' : 37080.01  , 'miaplus' :  38684.52  },
            'non_ricordo' : { 'mia' : 37157.74, 'miaplus' :  38677.54    },
        }, 
        
        '30' : {
            'activitas' : { 'mia' : 47353.29 , 'miaplus' : 49852.63     },
            'soliditas' : { 'mia' : 48667.46, 'miaplus' :  51337.52  },
            'serenitas' : { 'mia' : 50024.69 , 'miaplus' :  52325.87   },
            'securitas' : { 'mia' : 48334.94  , 'miaplus' :  50457.89  },
            'aequitas' : { 'mia' : 48500.87  , 'miaplus' :  51071.76  },
            'non_ricordo' : { 'mia' :  48576.25, 'miaplus' :  51009.13    },
        }, 

        '35' : {
            'activitas' : { 'mia' : 59921.54 , 'miaplus' : 63818.87     },
            'soliditas' : { 'mia' : 62023.06 , 'miaplus' :  65946.17   },
            'serenitas' : { 'mia' : 64080.35 , 'miaplus' : 67456.69   },
            'securitas' : { 'mia' :  61396.10, 'miaplus' : 64607.08   },
            'aequitas' : { 'mia' : 61771.38 , 'miaplus' :   65675.78 },
            'non_ricordo' : { 'mia' : 61838.49, 'miaplus' : 65500.92  },
        }, 



    };

    var versamento2500 = {

        '2' : {
            'activitas' : { 'mia' : 5160.88  , 'miaplus' :  5183.73   },
            'soliditas' : { 'mia' : 5173.06, 'miaplus' :  5195.93  },
            'serenitas' : { 'mia' : 5185.25 , 'miaplus' :  5204.32   },
            'securitas' : { 'mia' : 5169.25 , 'miaplus' :  5189.06  },
            'aequitas' : { 'mia' :  5163.17  , 'miaplus' :  5186.01   },
            'non_ricordo' : { 'mia' :5170.32  , 'miaplus' :  5191.81   },
        },

        '5' : {
            'activitas' : { 'mia' : 13517.50 , 'miaplus' : 13638.58     },
            'soliditas' : { 'mia' : 13581.96, 'miaplus' : 13703.54    },
            'serenitas' : { 'mia' : 13646.68 , 'miaplus' :  13752.44  },
            'securitas' : { 'mia' :  13561.79, 'miaplus' :  13666.96  },
            'aequitas' : { 'mia' : 13557.75 , 'miaplus' :  13679.15  },
            'non_ricordo' : { 'mia' : 13573.14 , 'miaplus' :  13688.13   },
        }, 

        '10' : {
            'activitas' : { 'mia' : 29143.49  , 'miaplus' :  29634.96    },
            'soliditas' : { 'mia' : 29404.51, 'miaplus' :  29900.71   },
            'serenitas' : { 'mia' : 29668.04  , 'miaplus' :  30084.89   },
            'securitas' : { 'mia' : 29322.67 , 'miaplus' : 29750.91    },
            'aequitas' : { 'mia' : 29355.37  , 'miaplus' : 29850.68   },
            'non_ricordo' : { 'mia' : 29378.82 , 'miaplus' : 29844.43    },
        }, 

        '15' : {
            'activitas' : { 'mia' : 47143.58 , 'miaplus' : 48371.97     },
            'soliditas' : { 'mia' : 47773.11, 'miaplus' : 49020.17    },
            'serenitas' : { 'mia' :  48452.46, 'miaplus' :  49471.52  },
            'securitas' : { 'mia' : 47614.83 , 'miaplus' : 48613.90    },
            'aequitas' : { 'mia' :  47693.90, 'miaplus' : 48938.61   },
            'non_ricordo' : { 'mia' : 47735.58 , 'miaplus' : 48883.23     },
        }, 

        '20' : {
            'activitas' : { 'mia' : 67970.31 , 'miaplus' :  70280.43    },
            'soliditas' : { 'mia' : 69191.21 , 'miaplus' :  71549.67   },
            'serenitas' : { 'mia' : 70437.65 , 'miaplus' :  72437.66   },
            'securitas' : { 'mia' : 68806.96  , 'miaplus' :  70753.32   },
            'aequitas' : { 'mia' : 69037.22 , 'miaplus' : 71469.57    },
            'non_ricordo' : { 'mia' : 69088.67, 'miaplus' :  71298.13   },
        }, 

        '25' : {
            'activitas' : { 'mia' : 91921.34  , 'miaplus' : 95893.06    },
            'soliditas' : { 'mia' :94015.09  , 'miaplus' : 98232.67    },
            'serenitas' : { 'mia' :  96164.91, 'miaplus' :  99782.01  },
            'securitas' : { 'mia' : 93354.84 , 'miaplus' : 96848.47    },
            'aequitas' : { 'mia' :  93882.61 , 'miaplus' : 97954.00    },
            'non_ricordo' : { 'mia' : 93867.76 , 'miaplus' : 97742.04    },
        }, 

        '30' : {
            'activitas' : { 'mia' : 119602.88  , 'miaplus' : 125927.25    },
            'soliditas' : { 'mia' : 122928.25 , 'miaplus' :  129460.11  },
            'serenitas' : { 'mia' : 126362.63  , 'miaplus' : 132185.80   },
            'securitas' : { 'mia' :  121877.54, 'miaplus' : 127458.83    },
            'aequitas' : { 'mia' : 122717.26  , 'miaplus' : 129460.11   },
            'non_ricordo' : { 'mia' : 122697.71 , 'miaplus' :   128898.42   },
        }, 

        '35' : {
            'activitas' : { 'mia' : 151327.07  , 'miaplus' :  161187.30    },
            'soliditas' : { 'mia' : 156643.85, 'miaplus' : 166569.59    },
            'serenitas' : { 'mia' : 161848.87 , 'miaplus' :  170391.44   },
            'securitas' : { 'mia' : 155057.65  , 'miaplus' :  163181.54  },
            'aequitas' : { 'mia' : 156325.09  , 'miaplus' : 166227.12    },
            'non_ricordo' : { 'mia' : 156240.51, 'miaplus' : 165511.40    },
        }, 

        '35_PIP' : {
            'activitas' : 120243 ,
            'aequitas' : 128491 ,
            'soliditas' : 128491 ,
            'securitas' : 141601,
            'serenitas' : 138524,
            'non_ricordo' : 131470,
        },

        '35_FPA' : {
            'activitas' : 138248 ,
            'aequitas' : 145341 ,
            'soliditas' : 145341 ,
            'securitas' : 153179,
            'serenitas' : 155690,
            'non_ricordo' : 147560,
        },  

        '35_plurifonds' : {
            'activitas' : 138248 ,
            'aequitas' : 151634 ,
            'soliditas' :  148598,
            'securitas' : 153490 ,
            'serenitas' : 160201 ,
            'non_ricordo' : 150434 ,
        }
    };

    var versamento3000 = {
       
        '2' : {
            'activitas' : { 'mia' : 6204.93  , 'miaplus' : 6232.37     },
            'soliditas' : { 'mia' : 6219.56 , 'miaplus' : 6247.02    },
            'serenitas' : { 'mia' : 6234.20  , 'miaplus' : 6257.10   },
            'securitas' : { 'mia' : 6214.99  , 'miaplus' : 6237.86    },
            'aequitas' : { 'mia' : 6209.50  , 'miaplus' : 6236.94   },
            'non_ricordo' : { 'mia' : 6216.63 , 'miaplus' : 6242.26    },
        }, 

        '5' : {
            'activitas' : { 'mia' : 16240.31 , 'miaplus' : 16390.62     },
            'soliditas' : { 'mia' : 16322.58 , 'miaplus' : 16468.68   },
            'serenitas' : { 'mia' : 16400.36  , 'miaplus' : 16522.54   },
            'securitas' : { 'mia' : 16298.35 , 'miaplus' :  16419.85  },
            'aequitas' : { 'mia' : 16298.35 , 'miaplus' :  16444.25  },
            'non_ricordo' : { 'mia' : 16311.99 , 'miaplus' :  16449.19   },
        }, 

        '10' : {
            'activitas' : { 'mia' : 35011.18  , 'miaplus' :  35601.64   },
            'soliditas' : { 'mia' : 35324.77 , 'miaplus' : 35920.93   },
            'serenitas' : { 'mia' : 35641.39  , 'miaplus' :  36162.41  },
            'securitas' : { 'mia' : 35226.45 , 'miaplus' : 35740.95   },
            'aequitas' : { 'mia' :  35265.74, 'miaplus' : 35880.85   },
            'non_ricordo' : { 'mia' : 35293.91 , 'miaplus' : 35861.36    },
        }, 

        '15' : {
            'activitas' : { 'mia' :  56666.11 , 'miaplus' : 58094.64     },
            'soliditas' : { 'mia' : 57422.96, 'miaplus' :  58873.21   },
            'serenitas' : { 'mia' : 58191.32  , 'miaplus' :  59415.34  },
            'securitas' : { 'mia' : 57185.22 , 'miaplus' :  58433.82   },
            'aequitas' : { 'mia' :  57327.73 , 'miaplus' : 58824.20    },
            'non_ricordo' : { 'mia' : 57358.67 , 'miaplus' : 58728.24     },
        }, 

        '20' : {
            'activitas' : { 'mia' : 81655.05  , 'miaplus' : 84430.79     },
            'soliditas' : { 'mia' : 83122.04, 'miaplus' : 85955.85    },
            'serenitas' : { 'mia' : 84619.70  , 'miaplus' : 87022.82    },
            'securitas' : { 'mia' :  82660.34  , 'miaplus' :  84998.99   },
            'aequitas' : { 'mia' : 83029.46 , 'miaplus' :  85859.60   },
            'non_ricordo' : { 'mia' : 83017.32 , 'miaplus' : 85653.61     },
        }, 

        '25' : {
            'activitas' : { 'mia' : 110460.70  , 'miaplus' :  115234.64   },
            'soliditas' : { 'mia' :112977.36  , 'miaplus' :117879.21     },
            'serenitas' : { 'mia' : 115561.40  , 'miaplus' : 119738.41    },
            'securitas' : { 'mia' : 112183.75  , 'miaplus' : 116383.03   },
            'aequitas' : { 'mia' : 112818.11  , 'miaplus' : 117711.86    },
            'non_ricordo' : { 'mia' : 112800.26 , 'miaplus' :  117389.43    },
        }, 

        '30' : {
            'activitas' : { 'mia' :  143523.46 , 'miaplus' :  151373.67    },
            'soliditas' : { 'mia' : 147767.60, 'miaplus' : 155621.68    },
            'serenitas' : { 'mia' : 151897.19  , 'miaplus' :  158622.96  },
            'securitas' : { 'mia' : 146504.20  , 'miaplus' : 152950.59   },
            'aequitas' : { 'mia' : 147513.90  , 'miaplus' :  155352.13   },
            'non_ricordo' : { 'mia' : 147441.27 , 'miaplus' :  154784.20    },
        }, 

        '35' : {
            'activitas' : { 'mia' :  181960.67 , 'miaplus' : 193424.76     },
            'soliditas' : { 'mia' :  187972.62 , 'miaplus' :  200295.45   },
            'serenitas' : { 'mia' : 194218.64  , 'miaplus' : 204892.70    },
            'securitas' : { 'mia' : 186069.18  , 'miaplus' :  196220.04  },
            'aequitas' : { 'mia' :  187972.62 , 'miaplus' : 199883.50    },
            'non_ricordo' : { 'mia' : 187638.75  , 'miaplus' :  198943.29    },
        }, 
        
    };

    var versamento5000 = {

        '2' : {
            'activitas' : { 'mia' : 10379.65  , 'miaplus' : 10425.45     },
            'soliditas' : { 'mia' : 10404.06 , 'miaplus' :  10451.44   },
            'serenitas' : { 'mia' : 10428.50  , 'miaplus' :   10468.27   },
            'securitas' : { 'mia' : 10397.96  , 'miaplus' : 10436.15   },
            'aequitas' : { 'mia' : 10394.91  , 'miaplus' : 10440.73    },
            'non_ricordo' : { 'mia' : 10401.02 , 'miaplus' :  10444.41    },
        }, 

        '5' : {
            'activitas' : { 'mia' : 27147.77 , 'miaplus' :  27390.81    },
            'soliditas' : { 'mia' : 27277.15, 'miaplus' : 27521.22    },
            'serenitas' : { 'mia' : 27407.08  , 'miaplus' : 27611.20   },
            'securitas' : { 'mia' : 27236.66  , 'miaplus' : 27447.80    },
            'aequitas' : { 'mia' : 27252.85 , 'miaplus' :  27496.73  },
            'non_ricordo' : { 'mia' :  27264.30 , 'miaplus' :  27493.55    },
        }, 

        '10' : {
            'activitas' : { 'mia' : 58482.16  , 'miaplus' :  59468.63    },
            'soliditas' : { 'mia' :  59006.07, 'miaplus' : 60035.55   },
            'serenitas' : { 'mia' : 59535.03 , 'miaplus' :  60405.48   },
            'securitas' : { 'mia' :  58874.62, 'miaplus' : 59701.37   },
            'aequitas' : { 'mia' :  58973.18  , 'miaplus' : 59968.56   },
            'non_ricordo' : { 'mia' : 58974.21 , 'miaplus' :  59915.92    },
        }, 

        '15' : {
            'activitas' : { 'mia' : 94600.15 , 'miaplus' :  97066.21    },
            'soliditas' : { 'mia' : 95943.58 , 'miaplus' : 98367.51    },
            'serenitas' : { 'mia' : 97227.80  , 'miaplus' :   99273.62   },
            'securitas' : { 'mia' : 95546.21  , 'miaplus' : 97551.90    },
            'aequitas' : { 'mia' : 95863.95 , 'miaplus' : 98285.60    },
            'non_ricordo' : { 'mia' : 95836.34, 'miaplus' :  98108.97    },
        }, 

        '20' : {
            'activitas' : { 'mia' : 136394.62  , 'miaplus' :  141032.83    },
            'soliditas' : { 'mia' : 138845.92  , 'miaplus' : 143581.19    },
            'serenitas' : { 'mia' : 141348.50 , 'miaplus' :  145364.09  },
            'securitas' : { 'mia' : 138074.43  , 'miaplus' :  141982.28   },
            'aequitas' : { 'mia' : 138691.22  , 'miaplus' : 143581.19    },
            'non_ricordo' : { 'mia' :  138670.94, 'miaplus' :  143108.32     },
        }, 

        '25' : {
            'activitas' : { 'mia' : 184360.09  , 'miaplus' : 192602.33    },
            'soliditas' : { 'mia' : 188561.44, 'miaplus' : 197024.55    },
            'serenitas' : { 'mia' : 193148.73  , 'miaplus' : 200133.50   },
            'securitas' : { 'mia' : 187500.68 , 'miaplus' : 194246.96    },
            'aequitas' : { 'mia' :  188561.44 , 'miaplus' :  196744.72   },
            'non_ricordo' : { 'mia' : 188426.47 , 'miaplus' :  196150.41   },
        }, 

        '30' : {
            'activitas' : { 'mia' : 240025.44  , 'miaplus' : 252725.27     },
            'soliditas' : { 'mia' : 246703.02 , 'miaplus' :  259819.61  },
            'serenitas' : { 'mia' : 253599.57  , 'miaplus' :  264831.88   },
            'securitas' : { 'mia' :  244593.11  , 'miaplus' :  255800.83   },
            'aequitas' : { 'mia' : 246703.02  , 'miaplus' : 259819.61   },
            'non_ricordo' : { 'mia' : 246324.83 , 'miaplus' :  258599.44    },
        }, 

        '35' : {
            'activitas' : { 'mia' : 303882.89  , 'miaplus' : 323035.38     },
            'soliditas' : { 'mia' : 313926.73 , 'miaplus' : 334513.98    },
            'serenitas' : { 'mia' : 324361.69  , 'miaplus' : 342194.46    },
            'securitas' : { 'mia' : 310746.76  , 'miaplus' : 327705.35    },
            'aequitas' : { 'mia' : 313926.73  , 'miaplus' : 333825.76    },
            'non_ricordo' : { 'mia' : 313368.96 , 'miaplus' : 332254.99   },
        }, 
        
    };

    var trasferimento = {

        '2' : {
            'activitas' : { 'mia' : 52878.57   , 'miaplus' : 53191.23     },
            'soliditas' : { 'mia' : 53045.33  , 'miaplus' :  53359.04    },
            'serenitas' : { 'mia' : 53211.60  , 'miaplus' :  53473.84    },
            'securitas' : { 'mia' : 52997.21  , 'miaplus' :  53258.66  },
            'aequitas' : { 'mia' : 53037.94  , 'miaplus' :  53351.79   },
            'non_ricordo' : { 'mia' : 53034.13 , 'miaplus' :  53326.91   },
        }, 

        '5' : {
            'activitas' : { 'mia' :  57558.20  , 'miaplus' : 58413.14    },
            'soliditas' : { 'mia' : 58013.24 , 'miaplus' : 58875.12    },
            'serenitas' : { 'mia' : 58469.08  , 'miaplus' : 59192.42     },
            'securitas' : { 'mia' : 57881.71  , 'miaplus' : 58598.49   },
            'aequitas' : { 'mia' : 57994.22 , 'miaplus' : 58856.44    },
            'non_ricordo' : { 'mia' : 57983.29 , 'miaplus' : 58787.12    },
        }, 

        '10' : {
            'activitas' : { 'mia' : 66305.64   , 'miaplus' : 68291.15     },
            'soliditas' : { 'mia' : 67358.75  , 'miaplus' : 69376.22     },
            'serenitas' : { 'mia' : 68422.05  , 'miaplus' :  70126.39    },
            'securitas' : { 'mia' : 67053.47  , 'miaplus' : 68725.43   },
            'aequitas' : { 'mia' : 67318.84 , 'miaplus' : 69336.92    },
            'non_ricordo' : { 'mia' : 67291.75 , 'miaplus' : 69171.22    },
        }, 

        '15' : {
            'activitas' : { 'mia' : 76394.99   , 'miaplus' :  79853.60    },
            'soliditas' : { 'mia' : 78223.05  , 'miaplus' :  81765.13    },
            'serenitas' : { 'mia' :  80083.33 , 'miaplus' :  83095.43     },
            'securitas' : { 'mia' : 77691.61  , 'miaplus' : 80616.82   },
            'aequitas' : { 'mia' : 78160.24  , 'miaplus' : 81703.21    },
            'non_ricordo' : { 'mia' : 78110.64 , 'miaplus' : 81406.84    },
        }, 

        '20' : {
            'activitas' : { 'mia' :  88032.08  , 'miaplus' : 93387.74     },
            'soliditas' : { 'mia' : 90852.94  , 'miaplus' :  96381.22    },
            'serenitas' : { 'mia' : 93746.14  , 'miaplus' : 98478.32     },
            'securitas' : { 'mia' : 90030.56  , 'miaplus' :  94580.06  },
            'aequitas' : { 'mia' : 90765.16  , 'miaplus' : 96294.62    },
            'non_ricordo' : { 'mia' : 90685.38 , 'miaplus' :  95824.39   },
        }, 

        '25' : {
            'activitas' : { 'mia' : 101454.36   , 'miaplus' :  109229.79    },
            'soliditas' : { 'mia' : 105535.36  , 'miaplus' :   113624.90    },
            'serenitas' : { 'mia' : 109754.02  , 'miaplus' :  116724.33   },
            'securitas' : { 'mia' : 104342.25 , 'miaplus' :  110976.15  },
            'aequitas' : { 'mia' :  105420.46, 'miaplus' :  113511.53   },
            'non_ricordo' : { 'mia' :  105301.29 , 'miaplus' : 112813.34    },
        }, 

        '30' : {
            'activitas' : { 'mia' : 116935.68   , 'miaplus' :  127773.29    },
            'soliditas' : { 'mia' : 122603.87  , 'miaplus' :  133968.53   },
            'serenitas' : { 'mia' : 128509.48  , 'miaplus' : 138366.35     },
            'securitas' : { 'mia' : 120942.06  , 'miaplus' : 130228.97  },
            'aequitas' : { 'mia' : 122459.65  , 'miaplus' : 133826.36    },
            'non_ricordo' : { 'mia' : 122290.15 , 'miaplus' : 132832.70    },
        }, 

        '35' : {
            'activitas' : { 'mia' : 134791.91  , 'miaplus' : 149478.93    },
            'soliditas' : { 'mia' : 142446.26  , 'miaplus' :  157969.39    },
            'serenitas' : { 'mia' : 150484.12 , 'miaplus' :  164036.47    },
            'securitas' : { 'mia' : 140195.83 , 'miaplus' : 152836.24   },
            'aequitas' : { 'mia' : 142270.53  , 'miaplus' :  157796.53  },
            'non_ricordo' : { 'mia' : 142037.73, 'miaplus' : 156423.51    },
        }, 

    };

    var plurifonds = {

        '500' : {
            '2' : {
                'activitas' : 980.44,
                'soliditas' : 985.65,
                'serenitas' : 991.02,
                'securitas' : 988.03,
                'aequitas' : 977.46,
                'non_ricordo' : 984.52,
            }, 
            
            '5' : {
                'activitas' : 2580.63,
                'soliditas' : 2608.54,
                'serenitas' : 2637.50 ,
                'securitas' : 2621.03 ,
                'aequitas' : 2590.68 ,
                'non_ricordo' : 2607.67 ,
            }, 

            '10' : {
                'activitas' : 5519.65,
                'soliditas' :  5631.08 ,
                'serenitas' : 5748.13,
                'securitas' : 5681.37 ,
                'aequitas' : 5609.22 ,
                'non_ricordo' : 5637.89 ,
            }, 

            '15' : {
                'activitas' : 8834.13 ,
                'soliditas' : 9099.60,
                'serenitas' : 9381.99 ,
                'securitas' : 9220.51,
                'aequitas' : 9092.11 ,
                'non_ricordo' : 9125.67 ,
            }, 

            '20' : {
                'activitas' : 12569.34,
                'soliditas' : 13091.64,
                'serenitas' : 13624.31,
                'securitas' : 13325.33,
                'aequitas' : 13106.10 ,
                'non_ricordo' :  13143.34 ,
            }, 

            '25' : {
                'activitas' : 16792.13,
                'soliditas' :  17677.51,
                'serenitas' : 18592.26,
                'securitas' : 18052.08,
                'aequitas' : 17751.69,
                'non_ricordo' : 17773.13 ,
            }, 

            '30' : {
                'activitas' : 21536.17 ,
                'soliditas' : 22923.32,
                'serenitas' : 24417.37,
                'securitas' : 23555.78 ,
                'aequitas' : 23118.78,
                'non_ricordo' : 23110.28,
            }, 

            '35' : {
                'activitas' :  26891.14 ,
                'soliditas' :  28951.68 ,
                'serenitas' : 31201.42 ,
                'securitas' : 29900.28,
                'aequitas' : 29303.18,
                'non_ricordo' : 29249.54,
            }
        },

        '1000':{

            '2' : {
                'activitas' : 2016.23 ,
                'soliditas' : 2027.08 ,
                'serenitas' : 2037.96 ,
                'securitas' : 2031.91 ,
                'aequitas' : 2020.45,
                'non_ricordo' : 2026.73 ,
            }, 

            '5' : {
                'activitas' : 5268.71 ,
                'soliditas' : 5325.55,
                'serenitas' : 5384.53 ,
                'securitas' : 5350.98  ,
                'aequitas' : 5316.04 ,
                'non_ricordo' : 5329.16 ,
            }, 

            '10' : {
                'activitas' : 11243.40,
                'soliditas' : 11470.69 ,
                'serenitas' : 11702.95 ,
                'securitas' : 11573.30 ,
                'aequitas' : 11483.47,
                'non_ricordo' : 11494.76,
            }, 

            '15' : {
                'activitas' : 17975.79 ,
                'soliditas' : 18532.64  ,
                'serenitas' : 19093.39,
                'securitas' : 18763.98,
                'aequitas' : 18594.01 ,
                'non_ricordo' : 18591.96,
            }, 

            '20' : {
                'activitas' : 25584.29 ,
                'soliditas' : 26621.16,
                'serenitas' : 27738.24,
                'securitas' : 27097.67,
                'aequitas' : 26798.70,
                'non_ricordo' : 26768.01 ,
            }, 

            '25' : {
                'activitas' : 34146.82 ,
                'soliditas' : 35952.84,
                'serenitas' : 37818.89 ,
                'securitas' : 36716.92,
                'aequitas' : 36256.21,
                'non_ricordo' : 36178.34 ,
            }, 

            '30' : {
                'activitas' :  43802.01 ,
                'soliditas' : 46632.44 ,
                'serenitas' : 49595.84 ,
                'securitas' : 47841.15 ,
                'aequitas' : 47191.97 ,
                'non_ricordo' : 47012.69,
            }, 

            '35' : {
                'activitas' : 54751.74 ,
                'soliditas' : 58842.94,
                'serenitas' : 63428.98,
                'securitas' : 60776.58,
                'aequitas' : 59800.55 ,
                'non_ricordo' : 59520.16 ,
            }, 

        },

        '2500':{

            '2' : {
                'activitas' : 5126.71  ,
                'soliditas' : 5154.04  ,
                'serenitas' : 5181.44,
                'securitas' :  5165.45 ,
                'aequitas' :  5151.76,
                'non_ricordo' : 5155.88  ,
            }, 

            '5' : {
                'activitas' :  13333.71 ,
                'soliditas' : 13481.37 ,
                'serenitas' : 13626.43 ,
                'securitas' : 13541.64  ,
                'aequitas' : 13497.42 ,
                'non_ricordo' : 13496.11 ,
            }, 

            '10' : {
                'activitas' :  28406.87 ,
                'soliditas' : 28981.62,
                'serenitas' :29585.41 ,
                'securitas' : 29241.08  ,
                'aequitas' : 29094.83,
                'non_ricordo' : 29061.96  ,
            }, 

            '15' : {
                'activitas' : 45423.09  ,
                'soliditas' : 46793.63 ,
                'serenitas' : 48251.52,
                'securitas' : 47417.83 ,
                'aequitas' : 47104.55 ,
                'non_ricordo' :  46998.12 ,
            }, 

            '20' : {
                'activitas' :  64597.54 ,
                'soliditas' : 67219.97 ,
                'serenitas' : 70045.36 ,
                'securitas' :  68425.19 ,
                'aequitas' : 67894.84 ,
                'non_ricordo' : 67636.58  ,
            }, 

            '25' : {
                'activitas' :  86200.33 ,
                'soliditas' : 90767.66 ,
                'serenitas' : 95486.97  ,
                'securitas' : 92700.02  ,
                'aequitas' : 91921.34 ,
                'non_ricordo' : 91415.26  ,
            }, 

            '30' : {
                'activitas' : 110617.17 ,
                'soliditas' : 117778.89 ,
                'serenitas' : 125277.45,
                'securitas' : 120837.36  ,
                'aequitas' : 119602.88 ,
                'non_ricordo' :  118822.75 ,
            }, 

            '35' : {
                'activitas' : 138248.27  ,
                'soliditas' : 148598.28 ,
                'serenitas' : 160200.87,
                'securitas' : 153490.26  ,
                'aequitas' : 151633.89,
                'non_ricordo' :  150434.31 ,
            }, 

         },

         '3000':{

            '2' : {
                'activitas' :  6162.97  ,
                'soliditas' : 6196.71  ,
                'serenitas' : 6229.62 ,
                'securitas' :  6210.41  ,
                'aequitas' : 6195.80  ,
                'non_ricordo' :   6199.10  ,
            },

            '5' : {
                'activitas' :  16024.29 ,
                'soliditas' : 16196.90 ,
                'serenitas' : 16376.02 ,
                'securitas' : 16274.14   ,
                'aequitas' : 16221.00  ,
                'non_ricordo' : 16218.47  ,
            },

            '10' : {
                'activitas' :  34126.17 ,
                'soliditas' : 34836.10  ,
                'serenitas' : 35542.12 ,
                'securitas' : 35128.42   ,
                'aequitas' : 34972.19  ,
                'non_ricordo' :  34921.00 ,
            },

            '15' : {
                'activitas' : 54552.64   ,
                'soliditas' : 56198.84  ,
                'serenitas' :57949.96 ,
                'securitas' :  56948.59  ,
                'aequitas' : 56619.18  ,
                'non_ricordo' : 56453.84   ,
            },

            '20' : {
                'activitas' :   77602.50 ,
                'soliditas' : 80753.48 ,
                'serenitas' :84148.34  ,
                'securitas' :  82201.62  ,
                'aequitas' : 81564.37  ,
                'non_ricordo' :  81254.06  ,
            },

            '25' : {
                'activitas' :  103584.18 ,
                'soliditas' : 108921.19  ,
                'serenitas' : 114746.53  ,
                'securitas' : 111396.67  ,
                'aequitas' : 110460.70  ,
                'non_ricordo' : 109821.85   ,
            },

            '30' : {
                'activitas' : 132964.59   ,
                'soliditas' : 141334.67  ,
                'serenitas' : 150592.34 ,
                'securitas' : 145253.47   ,
                'aequitas' :  143769.11 ,
                'non_ricordo' :  142782.84  ,
            },

            '35' : {
                'activitas' : 165897.92  ,
                'soliditas' : 178678.32  ,
                'serenitas' :192241.04  ,
                'securitas' :  184188.31  ,
                'aequitas' : 181960.67 ,
                'non_ricordo' :  180593.25  ,
            },

         },

         '5000':{
            
            '2' : {
                'activitas' : 10311.12  ,
                'soliditas' :  10365.93  ,
                'serenitas' :  10420.86 ,
                'securitas' : 10390.33  ,
                'aequitas' : 10372.03,
                'non_ricordo' : 10372.05 ,   
            },

            '5' : {
                'activitas' : 26778.82 ,
                'soliditas' : 27075.23 ,
                'serenitas' : 27366.42,
                'securitas' :  27196.22  ,
                'aequitas' : 27131.64 ,
                'non_ricordo' : 27109.67 , 
            },

            '10' : {
                'activitas' : 57035.32  ,
                'soliditas' : 58189.66 ,
                'serenitas' : 59369.18,
                'securitas' : 58710.75  ,
                'aequitas' : 58482.16 ,
                'non_ricordo' : 58357.41, 
            },

            '15' : {
                'activitas' : 91146.17  ,
                'soliditas' : 93897.59 ,
                'serenitas' : 96824.40 ,
                'securitas' : 95150.72  ,
                'aequitas' : 94600.15 ,
                'non_ricordo' : 94323.81 , 
            },

            '20' : {
                'activitas' : 129622.91 ,
                'soliditas' : 134888.10,
                'serenitas' : 140560.87 ,
                'securitas' : 137307.92  ,
                'aequitas' : 136394.62 ,
                'non_ricordo' : 135754.88 , 
            },

            '25' : {
                'activitas' : 173120.77  ,
                'soliditas' :  182045.10 ,
                'serenitas' : 191514.95 ,
                'securitas' : 186184.53 ,
                'aequitas' : 184619.43,
                'non_ricordo' : 183496.96, 
            },

            '30' : {
                'activitas' :   221981.72,
                'soliditas' : 235959.86 ,
                'serenitas' : 251420.42 ,
                'securitas' : 242504.34  ,
                'aequitas' : 240025.44 ,
                'non_ricordo' : 238378.36 , 
            },

            '35' : {
                'activitas' : 277048.00  ,
                'soliditas' : 298399.27,
                'serenitas' : 321057.79,
                'securitas' : 307604.49  ,
                'aequitas' : 304499.47 ,
                'non_ricordo' : 301721.80 , 
            },

         }


    };

    var vf = {

        '500' : {
            'non_dichiaro' : 115.00,
            '15': 115.00,
            '28': 135.00 ,
            '55': 190.00 ,
            '75': 205.00 ,
            'oltre':215.00  
        },

        '1000' : {
            'non_dichiaro' : 230.00 ,
            '15': 230.00 ,
            '28': 270.00 ,
            '55': 380.00,
            '75': 410.00,
            'oltre': 430.00
        },
        
        '2500' : {
            'non_dichiaro' : 575.00,
            '15':  575.00 ,
            '28': 675.00 ,
            '55': 950.00 ,
            '75': 1025.00 ,
            'oltre': 1075.00 
        },

        '3000' : {
            'non_dichiaro' : 690.00 ,
            '15': 690.00 ,
            '28': 810.00 ,
            '55': 1140.00 ,
            '75': 1230.00,
            'oltre': 1290.00 
        },

        '5000' : {
            'non_dichiaro' : 1150.00 ,
            '15':1150.00  ,
            '28':1350.00  ,
            '55': 1900.00 ,
            '75': 2050.00 ,
            'oltre': 2150.00
        },

    };


    return {
        '500' : versamento500,
        '1000' : versamento1000,
        '2500' : versamento2500,
        '3000' : versamento3000,
        '5000' : versamento5000,
        'trasferimento' : trasferimento,
        'plurifonds' : plurifonds,
        'vf' : vf
    };

};