require ('rangeslider.js');
require ('slick-carousel');
require('select2')(jQuery);

var data = require('js/data');
montanti = new data();

var versamentoValues = [500, 1000, 2500, 3000, 5000];
var durataValues = [2, 5, 10, 15, 20, 25, 30, 35];


var getUrlParameter = function getUrlParameter(sParam) {
    var sPageURL = window.location.search.substring(1),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
        }
    }
};

function doTheMagic(versamento, selectedIsc, durata, rendimento){
    return versamento * (( Math.pow(( 1+ rendimento - selectedIsc / 100 ), 35) - 1 ) / ( rendimento - selectedIsc / 100 )) * ( 1 + rendimento - selectedIsc / 100 ) ;
}

function theAlgorithmIsMagic(){

    // Var
    var versamento = localStorage.getItem('versamento');
    var durata = localStorage.getItem('durata');
    var linea = localStorage.getItem('linea');
    //var dove = localStorage.getItem('dove');
    var plurifonds = localStorage.getItem('plurifonds');
    var rendimento = 0.04;
    var tipoItas;
    var assicurazioni = localStorage.getItem('assicurazioni').split(","); 

    // NUOVO CONTROLLO
    // if( plurifonds == 'true' && dove == 'agenzia'){
    //     if( assicurazioni.includes("persona") == true || assicurazioni.includes("casa") == true || assicurazioni.includes("previdenza") == true || assicurazioni.includes("risparmio") == true || assicurazioni.includes("impresa") == true ){
    //         tipoItas = 'miaplus';
    //     } else if( assicurazioni.includes("veicoli") == true && assicurazioni.includes("persona") != true && assicurazioni.includes("casa") != true && assicurazioni.includes("previdenza") != true && assicurazioni.includes("risparmio") != true && assicurazioni.includes("impresa") != true ) {
    //         tipoItas = 'mia';
    //     }
    // } else {
    //     tipoItas = 'mia';
    // }

    // VECCHIO CONTROLLO TIPOLOGIA ITAS BASATO SU TIPOLOGIA DI ASSICURAZIONE
    var assicurazioni = localStorage.getItem('assicurazioni').split(","); 

    if( assicurazioni.includes("persona") == true || assicurazioni.includes("casa") == true || assicurazioni.includes("previdenza") == true || assicurazioni.includes("risparmio") == true || assicurazioni.includes("impresa") == true ){
        tipoItas = 'miaplus';
    } else if( assicurazioni.includes("veicoli") == true && assicurazioni.includes("persona") != true && assicurazioni.includes("casa") != true && assicurazioni.includes("previdenza") != true && assicurazioni.includes("risparmio") != true && assicurazioni.includes("impresa") != true ) {
        tipoItas = 'mia';
    }
   
    var montante = montanti[versamento][durata][linea][tipoItas];
    var montante2500x35 = montanti['2500']['35'][linea][tipoItas];

    var confrontoPIP = montante2500x35 - montanti['2500']['35_PIP'][linea];
    var confrontoFPA = montante2500x35 - montanti['2500']['35_FPA'][linea];
    
    //var confrontoPlurifonds = montante2500x35 - montanti['2500']['35_plurifonds'][linea];
    var confrontoPlurifonds = montante - montanti['plurifonds'][versamento][durata][linea];


    var trasferimento = montanti['trasferimento'][durata][linea][tipoItas];

    


    var result = {
        'tipoItas' : tipoItas,
        'montante' : montante.toFixed(0).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.'),
        'confrontoPIP' : confrontoPIP.toFixed(0).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.'),
        'confrontoFPA' : confrontoFPA.toFixed(0).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.'),
        'confrontoPlurifonds' : confrontoPlurifonds.toFixed(0).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.'),
        'trasferimento' : trasferimento.toFixed(0).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    };

    return result;

}



(function( $ ) {

    $.each(['show', 'hide'], function (i, ev) {
        var el = $.fn[ev];
        $.fn[ev] = function () {
          this.trigger(ev);
          el.apply(this, arguments);
          return el;
        };
      });
	
	$(function() {

        // SAVE SEND MAIL

        function saveSendMail(){

            var assicurazioni = localStorage.getItem('assicurazioni').split(","); 
            var tipoItas;
            if( assicurazioni.includes("persona") == true || assicurazioni.includes("casa") == true || assicurazioni.includes("previdenza") == true || assicurazioni.includes("risparmio") == true || assicurazioni.includes("impresa") == true ){
                tipoItas = 'miaplus';
            } else if( assicurazioni.includes("veicoli") == true && assicurazioni.includes("persona") != true && assicurazioni.includes("casa") != true && assicurazioni.includes("previdenza") != true && assicurazioni.includes("risparmio") != true && assicurazioni.includes("impresa") != true ) {
                tipoItas = 'mia';
            }


            var postObject = { 
                'firstname': $('#firstname').val(),
                'lastname': $('#lastname').val(),
                'email': $('#email').val(),
                'citta': $('#city').val(),
                'telefono': $('#telefono').val(),
                'privacy': $("#privacy").is(':checked') == true ? 'true' : 'false',
                'socio': localStorage.getItem('socio'),
                'assicurazioni': localStorage.getItem('assicurazioni'),
                'plurifonds': localStorage.getItem('plurifonds'),
                'linea': localStorage.getItem('linea'),
                //'dove': localStorage.getItem('dove'),
                'versamento': localStorage.getItem('versamento'),
                'durata': localStorage.getItem('durata'),
                'results' : $('.results-mail').html(),
                'mailtitle' : $('.form_headline.passed h1').html(),
            };

            $.ajax({
                url: 'save',
                type: 'POST',
                data: postObject,
                beforeSend: function(xhr){
                    $('#form_invia input[type="submit"]').css('display','none');
                    $('.spinner').css('display','block');
                },
                success: function(data) {
                    if(parseInt(data) == 1){
                        localStorage.clear();
                   
                        var page_path;
                        if (tipoItas == 'mia'){
                            page_path = "/thank-you-mia";
                        } else if (tipoItas == 'miaplus'){
                            page_path = "/thank-you-miaplus";
                        }
                        window.location.href = $('body').attr('data-path') + $('body').attr('data-lang') + page_path;
                        
             
                    } else {
                        console.log(data);
                    }
                }, 
                error: function(xhr,status,error){
                    console.log(error);
                    $('.spinner').css('display','none');
                    $('.form-error').css('display','block');
                }
            });
        }


        $('#form_invia').on('submit', function(event){
            event.preventDefault();
            saveSendMail();
        });


        function step7(){
            var result = theAlgorithmIsMagic();
            $('#step7 .s7_durata').html(localStorage.getItem("durata"));
            $('#step7 .s7_montante').html(result.montante+'&euro;');
            
            if(localStorage.getItem("linea") != 'non_ricordo'){
                $('#step7 .confrontofisso_linea').html( localStorage.getItem("linea") );
            }
            $('#step7 .s7_confronto_pip_value').html(result.confrontoPIP+' &euro;');
            $('#step7 .s7_confronto_fpa_value').html(result.confrontoFPA+' &euro;');
            $('#step7 .s7_confronto_plurifonds_value').html(result.confrontoPlurifonds+' &euro;');
          
            if(localStorage.getItem("linea") != 'non_ricordo'){
                $('#step7 .trasferimento_linea').html( localStorage.getItem("linea") );
            }
         
            $('#step7 .trasferimento_durata').html(localStorage.getItem("durata"));
            $('#step7 .trasferimento_montante').html(result.trasferimento+'&euro;');

            // Vantaggio fiscale
            function checkVF(versamento, reddito){
                if( versamento != undefined && versamento != null && reddito != undefined && reddito != null ){
                    var vf = montanti['vf'][versamento][reddito];
                    $('#vf_result').html( vf );
                } else {
                    $('#vf_result').html('');
                }
            }
            $('#vf_versamento').on('change', function(){
                checkVF($('#vf_versamento').val(),$('#vf_reddito').val());
            });
            
            $('#vf_reddito').on('change', function(){
                checkVF($('#vf_versamento').val(),$('#vf_reddito').val());
            });

            $('#vf_versamento').val(localStorage.getItem('versamento'));

            checkVF($('#vf_versamento').val(),$('#vf_reddito').val());

            



            // User data reporting
            if( localStorage.getItem('socio') == 'true' ){
                $('.result_socio').addClass('active');
            } else {
                $('.result_socio').remove();
            }
            if( localStorage.getItem('plurifonds') == 'true' ){
                $('.result_fondo').addClass('active');
            } else {
                $('.result_fondo').remove();
            }
            var assicurazioni = localStorage.getItem('assicurazioni').split(",");
            assicurazioni.forEach(element => {
                $('.result_'+element).addClass('active');
            });
            $('.result_assicurazione').each(function(){
                if( $(this).hasClass('active') == 0){
                    $(this).remove();
                }
            });

            if( localStorage.getItem('versamento') != '' ){
                $('.s7_versamento_value').html(localStorage.getItem('versamento'));
            }
            if( localStorage.getItem('durata') != '' ){
                $('.s7_durata_value').html(localStorage.getItem('durata'));
            }
            // if( localStorage.getItem('dove') != 'false' ){
            //     var doveLabel = {
            //         'agenzia' : { 'it' : 'agenzia', 'de': 'agentur' },
            //         'banca' : { 'it' : 'banca', 'de': 'bank' }
            //     };
            //     $('.s7_dove_value').html(doveLabel[localStorage.getItem('dove')][$('body').attr('data-lang')]);
            // } else {
            //     $('.result_dove').remove();
            // }
            if(result.tipoItas == 'mia'){
                $('.s7_tipoitas').html('ITAS Mia');
            } else if (result.tipoItas == 'miaplus'){
                $('.s7_tipoitas').html('ITAS Mia+');
            }

            // form Headline controls [tutto, no_socio_no_plurifonds, solo_plurifonds, solo_assicurazione, solo_auto, banca]

            if( localStorage.getItem('socio') == 'true' && localStorage.getItem('plurifonds') == 'true' ){   
                
                if( assicurazioni.includes("persona") == true || assicurazioni.includes("casa") == true || assicurazioni.includes("previdenza") == true || assicurazioni.includes("risparmio") == true || assicurazioni.includes("impresa") == true ){
                    $('#step7 .form_headline.tutto').css('display','block').addClass('passed');
                } else if( assicurazioni.includes("veicoli") == true && assicurazioni.includes("persona") != true && assicurazioni.includes("casa") != true && assicurazioni.includes("previdenza") != true && assicurazioni.includes("risparmio") != true && assicurazioni.includes("impresa") != true ) {
                    $('#step7 .form_headline.solo_auto').css('display','block').addClass('passed');
                }
                // if( localStorage.getItem('dove') == 'agenzia' ){
                //     if( assicurazioni.includes("persona") == true || assicurazioni.includes("casa") == true || assicurazioni.includes("previdenza") == true || assicurazioni.includes("risparmio") == true || assicurazioni.includes("impresa") == true ){
                //         $('#step7 .form_headline.tutto').css('display','block').addClass('passed');
                //     } else if( assicurazioni.includes("veicoli") == true && assicurazioni.includes("persona") != true && assicurazioni.includes("casa") != true && assicurazioni.includes("previdenza") != true && assicurazioni.includes("risparmio") != true && assicurazioni.includes("impresa") != true ) {
                //         $('#step7 .form_headline.solo_auto').css('display','block').addClass('passed');
                //     }
                // } else {
                //     $('#step7 .form_headline.banca').css('display','block').addClass('passed');
                // }

            } else if( localStorage.getItem('socio') == 'false' && localStorage.getItem('plurifonds') == 'false' ){
                $('#step7 .form_headline.no_socio_no_plurifonds').css('display','block').addClass('passed');
            } else if( localStorage.getItem('socio') == 'false' && localStorage.getItem('plurifonds') == 'true' ){
                $('#step7 .form_headline.solo_plurifonds').css('display','block').addClass('passed');
            } else if( localStorage.getItem('socio') == 'true' && localStorage.getItem('plurifonds') == 'false' ){
                if( assicurazioni.includes("persona") == true || assicurazioni.includes("casa") == true || assicurazioni.includes("previdenza") == true || assicurazioni.includes("risparmio") == true || assicurazioni.includes("impresa") == true ){
                    $('#step7 .form_headline.solo_assicurazione').css('display','block').addClass('passed');
                } else if( assicurazioni.includes("veicoli") == true && assicurazioni.includes("persona") != true && assicurazioni.includes("casa") != true && assicurazioni.includes("previdenza") != true && assicurazioni.includes("risparmio") != true && assicurazioni.includes("impresa") != true ) {
                    $('#step7 .form_headline.solo_auto').css('display','block').addClass('passed');
                }
            } 


        }

        
        // POPULATE ACTIVE STEP WITH LOCALSTORAGE INFO (required?)
        $('body').on('changeClass', function (event, stepId) {
            switch (stepId) {
                case "#step1":
                    if(localStorage.getItem("socio")){
                        if( localStorage.getItem("socio") == "true" ){
                            $("input[name='socio']#si").prop("checked",true);
                        } else {
                            $("input[name='socio']#no").prop("checked",true);
                        }
                    }
                  break;
                case "#step7":
                    step7();
                    break;
                default:
           
              }
        });

        // STEP ACTIVATION
        var totalSteps = 7;
        function activateStep(step){
            $('html,body').animate({ scrollTop: 0 }, 150);
            var stepIndex = $('#step'+step).attr('data-stepindex');
            $('.step').removeClass('current');
            $('#step'+step).addClass('current').trigger('changeClass', '#step'+step );
            var statusPercentage = ( 100 / totalSteps ) * stepIndex ;
            if(statusPercentage == 100){
                $('.status_bar').animate({width: '0%' },{ duration: 0 });
            } else {
                $('.status_bar').animate({width: statusPercentage + '%' },{ duration: 300 });
            }
            
        }

        // ASSICURAZIONE SELECTION
        $('.assicurazione').click(function(){
            $(this).toggleClass('selected');
        });
        


        // STEP 0 ----------------------------------------------------------------
        $('#step0 button[data-action="next"]').click(function(){
            activateStep(1);
        });

        //STEP 1 ---------------------------------------------------------------- SOCIO / NON SOCIO ?
        $('#step1 button[data-action="next"]').click(function(){
            var socioValue = $("input[name='socio']:checked").val() == 'true' ? true : false;
            localStorage.setItem('socio', socioValue );
            
            if( $("input[name='socio']:checked").val() != null && $("input[name='socio']:checked").val() != undefined){
                if(socioValue == true ){
                    $('#step2-si').removeClass('vorrebbe');
                    activateStep('2-si');
                } else {
                    activateStep('2-no');
                }
            }
        });
        $('#step1 .btn__cancel[data-action="back"]').click(function(){
            activateStep('0');
        });

        // STEP 2 ---------------------------------------------------------------- SOCIO SELEZIONE ASSICURAZIONI
        $('#step2-si button[data-action="next"]').click(function(){    
            var assicurazioni = [];
            $('.assicurazione.selected').each(function(){
                assicurazioni.push($(this).attr('data-value'));
            });
            if(typeof assicurazioni !== 'undefined' && assicurazioni.length > 0){
                localStorage.setItem('assicurazioni', assicurazioni );
                activateStep('3');
            } else {
       
            } 
        });
        $('#step2-si .btn__cancel[data-action="back"]').click(function(){
            activateStep('1');
        });

        $('#step2-no button[data-action="next"]').click(function(){    
            $('#step2-si').addClass('vorrebbe');
            activateStep('2-si');
        });
        $('#step2-no .btn__cancel[data-action="back"]').click(function(){
            activateStep('1');
        });

        // STEP 3 ---------------------------------------------------------------- PLURIFONDS ?
        $('#step3 button[data-action="next"]').click(function(){    
            var plurifondsValue = $("input[name='plurifonds']:checked").val() == 'true' ? true : false;
            localStorage.setItem('plurifonds', plurifondsValue );
       
            if( $("input[name='plurifonds']:checked").val() != null && $("input[name='plurifonds']:checked").val() != undefined){
                if(plurifondsValue == true ){
                    $('#step4-si').removeClass('vorrebbe');
                    $('#step5').addClass('plurifonds-si');
                    $('#step6').addClass('plurifonds-si');
                    activateStep('4-si');
                } else {
                    activateStep('4-no');
                    $('#step5').removeClass('plurifonds-si');
                    $('#step6').removeClass('plurifonds-si');
                }
            }
        });
        $('#step3 .btn__cancel[data-action="back"]').click(function(){
            // Check se ha inserito socio true o false
            if(localStorage.getItem("socio")){
                if( localStorage.getItem("socio") == 'true' ){
                    activateStep('2-si');
                } else {
                    activateStep('2-no');
                }
            }  
        });


        // STEP 4 ---------------------------------------------------------------- SELEZIONE LINEE
        $('#step4-si button[data-action="next"]').click(function(){    
            var linea = $("input[name='linea']:checked").val();
            localStorage.setItem('linea', linea );
            
            if( localStorage.getItem('plurifonds') == 'true' ){
                if( $("input[name='linea']:checked").val() != null && $("input[name='linea']:checked").val() != undefined){
                     activateStep('5');
                    //activateStep('4-dove');
                }
            } else {
                //localStorage.setItem('dove', false );
                activateStep('5');
            }

        });
        $('#step4-si .btn__cancel[data-action="back"]').click(function(){
            activateStep('3'); 
        });

        $('#step4-no button[data-action="next"]').click(function(){    
            $('#step4-si').addClass('vorrebbe');
            activateStep('4-si');
        });
        $('#step4-no .btn__cancel[data-action="back"]').click(function(){
            activateStep('3');
        });

        // $('#step4-dove button[data-action="next"]').click(function(){    
        //     var doveValue = $("input[name='dove']:checked").val();
        //     localStorage.setItem('dove', doveValue );
        //     activateStep('5');
        // });
        // $('#step4-dove .btn__cancel[data-action="back"]').click(function(){
        //     activateStep('3');
        // });

        // Show dislaimer if non so/non ricordo
        $('.linea.radio-choice').click(function(){
            if( $(this).hasClass('nonricordoso') == 1 ){
                $('.disclaimer-noplurifonds').addClass('active');
            } else {
                $('.disclaimer-noplurifonds').removeClass('active');
            }
        });
       

        // STEP 5 ---------------------------------------------------------------- VERSAMENTO
        $('#step5 button[data-action="next"]').click(function(){    
            var versamento = $("input[name='versamento']").val();
            localStorage.setItem('versamento', versamentoValues[versamento] );
            
            if( $("input[name='versamento']").val() != null && $("input[name='versamento']").val() != undefined && $("input[name='versamento']").val() != ''){
                activateStep('6');
                
            }
        });
        $('#step5 .btn__cancel[data-action="back"]').click(function(){
            if(localStorage.getItem("plurifonds")){
                if( localStorage.getItem("plurifonds") == 'true' ){
                    activateStep('4-si');
                } else {
                    activateStep('3');
              
                }
            }
        });
        
        // STEP 6 ---------------------------------------------------------------- DURATA
        $('#step6 button[data-action="next"]').click(function(){    
            var durata = $("input[name='durata']").val();
            localStorage.setItem('durata', durataValues[durata] );
            
            if( $("input[name='durata']").val() != null && $("input[name='durata']").val() != undefined && $("input[name='durata']").val() != ''){
                
                $('.loading').addClass('is-loading');
                activateStep('7');
                setTimeout(function(){
                    $('.loading').removeClass('is-loading');
                },2000);
                
            
            }
        });
        $('#step6 .btn__cancel[data-action="back"]').click(function(){
            if(localStorage.getItem("plurifonds")){
                if( localStorage.getItem("plurifonds") == 'true' ){
                    activateStep('5');
                    $('#step5').addClass('plurifonds-si');
                } else {
                    activateStep('5');
                    $('#step5').addClass('plurifonds-no');
                }
            }
        });
        
    
        var openSelect = function(selector){
            var element = $(selector)[0], worked = false;
           if (document.createEvent) { // all browsers
               var e = document.createEvent("MouseEvents");
               e.initMouseEvent("mousedown", true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
               worked = element.dispatchEvent(e);
           } else if (element.fireEvent) { // ie
               worked = element.fireEvent("onmousedown");
           }
           if (!worked) { // unknown browser / error
               alert("It didn't worked in your browser.");
           }   
       }

        function simulazioneHome(polizze, linea, giacenza){

            // var matrix = {
            //     'activitas' : { 'mia' : 0.65, 'miaplus' : 0.95 },
            //     'aequitas' : { 'mia' : 0.25, 'miaplus' : 0.55 },
            //     'soliditas' : { 'mia' : 0.30, 'miaplus' : 0.60 },
            //     'securitas' : { 'mia' : 0.25, 'miaplus' : 0.55 },
            //     'serenitas' : { 'mia' : 0.05, 'miaplus' : 0.35 }
            // };
            var matrix = {
                'activitas' : { 'mia' : 0.45, 'miaplus' : 0.30 },
                'aequitas' : { 'mia' : 0.15, 'miaplus' : 0.30 },
                'soliditas' : { 'mia' : 0.25, 'miaplus' : 0.30 },
                'securitas' : { 'mia' : 0.05, 'miaplus' : 0.25 },
                'serenitas' : { 'mia' : 0.05, 'miaplus' : 0.25 }
            };


            var maxMia = 1575;
            var maxMiaPlus = 2625;

        

            var assicurazioni = polizze.split(","); 

            if( assicurazioni.includes("persona") == true || assicurazioni.includes("casa") == true || assicurazioni.includes("previdenza") == true || assicurazioni.includes("risparmio") == true || assicurazioni.includes("impresa") == true ){
                tipoSocio = 'miaplus';
            } else if( assicurazioni.includes("veicoli") == true && assicurazioni.includes("persona") != true && assicurazioni.includes("casa") != true && assicurazioni.includes("previdenza") != true && assicurazioni.includes("risparmio") != true && assicurazioni.includes("impresa") != true ) {
                tipoSocio = 'mia';
            } else {
                tiposocio = false;
            }
          
            var contributo = 0;

            if(giacenza >= 1000){

                var giacenzaCalcolo = giacenza <= 350000 ? giacenza : 350000;
                var contributoBase = (giacenzaCalcolo * matrix[linea]['mia'] / 100);
                var contributoPlus = (giacenzaCalcolo * matrix[linea]['miaplus'] / 100);
                if( tipoSocio == 'miaplus' ){
                    contributo = contributoBase + contributoPlus;
                } else {
                    contributo = contributoBase;
                }
                
                // if (tipoSocio == 'mia' && contributo > maxMia ){
                //     contributo = maxMia;
                // } else if( tipoSocio == 'miaplus' && contributo > maxMiaPlus ){
                //     contributo = maxMiaPlus;
                // }
            }

           

            if( contributo % 1 != 0 ){
                return contributo.toFixed(1).replace(".", ",");
            } else {
                return contributo.toFixed(0);
            }

            

        }

        // INIT HOME
        if($('body').hasClass('home') == 1){
            $('.stories-carousel').slick({
                slidesToShow: 3,
                slidesToScroll: 1,
                arrows: true,
                infinite: false,
                prevArrow: $('.stories-carousel-arrows .prev'),
                nextArrow: $('.stories-carousel-arrows .next'),
                responsive: [
                    {
                      breakpoint: 768,
                      settings: {
                        slidesToShow: 1
                      }
                    }
                ]
            });

            $('.multiselect').select2({
                minimumResultsForSearch: -1
            });

            var distance = $('.cosa').offset().top - $(window).height()/2;

            $(window).scroll(function(){
                if ( $(window).scrollTop() >= distance ) {
                    if( $('.mobile-cta').hasClass('inview') == 0){
                        $('.mobile-cta').addClass('inview');
                    }
                } else {
                    if( $('.mobile-cta').hasClass('inview') == 1){
                        $('.mobile-cta').removeClass('inview');
                    }
                }
            });

            $(window).resize(function(){
                var max = 0;
                $('.itasmia_variante .detail p').each(function(){
                    if( $(this).outerHeight() > max ){
                        max = $(this).outerHeight();
                    }
                    if( $(window).width() >= 768  ){
                        $('.itasmia_variante .detail p').css('min-height', max + 'px');
                    } else {
                        $('.itasmia_variante .detail p').removeAttr("style");
                    }
                })

            });
            setTimeout(function(){
                $(window).trigger('resize');
            }, 100);
           
      
            


            function checkSimulatoreHome(){
                if( $('#giacenza').val() > 0 && $('#linea').val() != undefined && $('#linea').val() != null && assicurazioniSelected != null && assicurazioniSelected != undefined ){
                    // Forzo assicurazione veicoli così per difetto va su ITAS MIA
                    $('#simulatore-risultato').html( simulazioneHome(assicurazioniSelected, $('#linea').val(), $('#giacenza').val()) );
                } else {
                    $('#simulatore-risultato').html('');
                }
            }
            var assicurazioniSelected;
            $('#giacenza').on('keyup', function(){
                if(assicurazioniSelected != ''){ checkSimulatoreHome(); }
            });
            
            $('#linea').on('change', function(){
                if(assicurazioniSelected != ''){ checkSimulatoreHome(); }
            });
            
            $('.assicurazioni .assicurazione').click(function(){
                assicurazioniSelected = '';
                $('.assicurazione').each(function(){
                    if ( $(this).hasClass('selected') == 1){
                        assicurazioniSelected = assicurazioniSelected + $(this).attr('data-value') + ',';
                    }
                });
                if(assicurazioniSelected != ''){
                    checkSimulatoreHome();
                } else {
                    $('#simulatore-risultato').html('');
                }
            });

            $('.burger').click(function(){
                $('body').toggleClass('mobile-menu-open');
            });

            $(window).scroll(function(){
       
                if( $(window).scrollTop() > 10 ){
                    $('header').addClass('scrolled');
                } else {
                    $('header').removeClass('scrolled');
                }
            });

            $('.menu li').click(function(){

                if($(window).width()<768){
                    $('body').toggleClass('mobile-menu-open');
                }

                var target= $(this).find('a').attr('href');
                if(target && target != null && target != undefined && target != "" && target!= '#'){
                    $('html,body').animate({
                        scrollTop: $(target).offset().top - 60
                    },{
                        duration: 600,
                        easing: 'easeInOutExpo'
                    });
                }
            });
            

        }
  

        // INIT HOME
        if($('body').hasClass('landing') == 1){
           
            

            $(window).scroll(function(){
                // Add class to header if page is scrolled
                if( $(window).scrollTop() > 10 ){
                    $('header').addClass('scrolled');
                } else {
                    $('header').removeClass('scrolled');
                }
            });
            

        }


        // FORM HELP
        $('#form_help').submit(function(event){
            event.preventDefault();

            var postObject = { 
                'firstname': $('#firstname_h').val(),
                'lastname': $('#lastname_h').val(),
                'email': $('#email_h').val(),
                'telefono': $('#telefono_h').val(),
                'privacy': $("#privacy_h").is(':checked') == true ? 'true' : 'false',
                'messaggio': $('#messaggio_h').val(),
            };

            $.ajax({
                url: 'sendhelp',
                type: 'POST',
                data: postObject,
                beforeSend: function(xhr){
                    $('#form_help input[type="submit"]').css('display','none');
                    $('.spinner').css('display','block');
                },
                success: function(data) {
                  
                    if(data == 1){
                        $('.spinner, #form_help').css('display','none');
                        $('.form_headline .default-title').css('display','none');
                        $('.form-ok').css('display','block');
                        ga('send', 'event', 'Help', 'Submit Form', 'Submit form bisogno di aiuto '+ $('body').attr('data-lang') );
                    } else {
                        $('.form-error').css('display','block');
                    }
                }, 
                error: function(xhr,status,error){
                    console.log(error);
                    $('.spinner').css('display','none');
                    $('.form-error').css('display','block');
                }
            });


        });

        // Apertura form help
        $('.help-trigger').click(function(){
            $('#help').css('display','block');
            $('body').addClass('modal-open');
        });

        // Chiusura form help
        $('.modal_help-close').click(function(){
            $('#help').css('display','none');
            $('body').removeClass('modal-open');
        });


        // INIT CALCOLA
        if($('body').hasClass('calcola') == 1){
       
            
            var $rsVersamento = $('.rs_versamento');
            $rsVersamento.rangeslider({
                polyfill: false,
                onSlide: function(position, value) {
                    $rsVersamento.next('.rangeslider').find('.rangeslider__handle').attr('data-content',versamentoValues[value] + ' €');
                }
            }); 
            $rsVersamento.rangeslider('update', true);

            var $rsDurata = $('.rs_durata');
            $rsDurata.rangeslider({
                polyfill: false,
                onSlide: function(position, value) {
                    var anniLabel;
                    if ( $('body').attr('data-lang') == 'de' ){
                        anniLabel = ' jahre';
                    } else {
                        anniLabel = ' anni';
                    }
                    $rsDurata.next('.rangeslider').find('.rangeslider__handle').attr('data-content',durataValues[value] + anniLabel);
                }
            }); 
            $rsDurata.rangeslider('update', true);

            var initStep = 0;
           
            
            localStorage.clear();
            activateStep( initStep );


            

            

        }

		

	});

})(jQuery);